import styled from 'styled-components';
import {MEDIA} from '../../constants';
import {getTypographySize, getTypographyWeight, getTypographyColor, getSpacing, getColor} from '../../theme/helpers';


export const Wrapper = styled.div`
  padding: ${getSpacing('lg')} 0;
  background-color: ${getColor('basicWhite')};
  padding-top: ${getSpacing('xxxl')};
`;
export const Header = styled.h1`
  font-size: ${getTypographySize('lg')};
  padding-bottom: ${getSpacing('xmd')};
  color: ${getTypographyColor('brandBlue')};
  font-weight: ${getTypographyWeight('semiBold')};

  @media (min-width: ${MEDIA.MED}) {
    font-size: ${getTypographySize('xxl')};
  }
`;

export const Subheader = styled.h3`
  font-size: ${getTypographySize('md')};
  padding-bottom: ${getSpacing('xmd')};
  font-weight: ${getTypographyWeight('normal')};
  color: ${getTypographyColor('brandBlue')};
`;

export const Paragraph = styled.p`
  color: ${getTypographyColor('grey')};
  font-size: ${getTypographySize('xs')};
  line-height: 21px;
  margin-bottom: ${getSpacing('md')};
  font-weight: ${getTypographyWeight('normal')};

  @media (min-width: ${MEDIA.MED}) {
    font-size: ${getTypographySize('small')};
    letter-spacing: 0.0125rem;
  }
`;

export const Link = styled.a`
  color: ${getTypographyColor('brandBlue')};
`;

export const List = styled.ul`
  list-style-type: disc;
  margin-bottom: ${getSpacing('md')};
`;

export const ListElement = styled.li`
  color: ${getTypographyColor('grey')};
  font-size: ${getTypographySize('xs')};
  line-height: 21px;
  letter-spacing: 0.0125rem;
  padding-bottom: ${getSpacing('sm')};
  margin-left: ${getSpacing('lg')};

  @media (min-width: ${MEDIA.MED}) {
    font-size: ${getTypographySize('small')};
  }
`;