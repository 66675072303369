import React from 'react';
import { ContentWrapper } from '../ContentWrapper/ContentWrapper';
import * as Styled from './PrivacyPolicy.styles';

export const PrivacyPolicy = () => (
    <Styled.Wrapper>
        <ContentWrapper>
            <Styled.Header>Polityka prywatności</Styled.Header>

            <Styled.Subheader>Dzień dobry!</Styled.Subheader>
            <Styled.Paragraph>Jeżeli tutaj trafiłeś, to niezawodny znak, że cenisz swoją prywatność. Doskonale to rozumiemy, dlatego przygotowaliśmy dla Ciebie ten dokument, w którym znajdziesz zasady przetwarzania danych osobowych oraz wykorzystywania plików cookies w związku z korzystaniem ze strony internetowej <Styled.Link href='https://patternapplied.com'>https://patternapplied.com</Styled.Link> oraz <Styled.Link href='https://cqrsapplied.pl'>https://cqrsapplied.pl</Styled.Link>.</Styled.Paragraph>
            <Styled.Paragraph>Informacja formalna na początek – administratorem stron jest firma <b>Pattern Applied Piotr Wyczesany, ul. Św. Filipa 23/3, 31-150 Kraków, NIP: 6792724626, REGON: 121005334</b>.</Styled.Paragraph>
            <Styled.Paragraph>W razie jakichkolwiek wątpliwości związanych z polityką prywatności, w każdej chwili możesz skontaktować się z nami, wysyłając wiadomość na adres: piotr@patternapplied.com.</Styled.Paragraph>

            <Styled.Subheader>Skrócona wersja – najważniejsze informacje</Styled.Subheader>
            <Styled.Paragraph>Dbamy o Twoją prywatność, ale również o Twój czas. Dlatego przygotowaliśmy dla Ciebie skróconą wersję najważniejszych zasad związanych z ochroną prywatności.</Styled.Paragraph>
            <Styled.List>
                <Styled.ListElement>Zapisując się do newslettera czy po prostu kontaktując się z nami, przekazujesz nam swoje dane osobowe, a my gwarantujemy Ci, że Twoje dane pozostaną poufne, bezpieczne i nie zostaną udostępnione jakimkolwiek podmiotom trzecim bez wyraźnej podstawy prawnej i uzasadnionej potrzeby.</Styled.ListElement>
                <Styled.ListElement>Powierzamy przetwarzanie danych osobowych tylko sprawdzonym i zaufanym podmiotom świadczącym usługi związane z przetwarzaniem danych osobowych.</Styled.ListElement>
                <Styled.ListElement>Korzystamy z narzędzi analitycznych np. Google Analytics, które zbierają informacje na temat Twoich odwiedzin strony, takie jak podstrony, które wyświetliłeś, czas, jaki spędziłeś na stronie czy przejścia pomiędzy poszczególnymi podstronami. W tym celu wykorzystywane są pliki cookies firmy Google LLC dotyczące usługi Google Analytics. W ramach mechanizmu do zarządzania ustawieniami plików cookies masz możliwość zadecydowania, czy wyrażasz zgodę na korzystanie przez nas w Twoim przypadku z cookies analitycznych, czy nie.</Styled.ListElement>
                <Styled.ListElement>Korzystamy z narzędzi marketingowych, takich jak Facebook Pixel, by kierować do Ciebie reklamy. Wiąże się to z wykorzystywaniem plików cookies firmy Facebook. W ramach ustawień plików cookies możesz zadecydować, czy wyrażasz zgodę na korzystanie przez nas w Twoim przypadku z cookies marketingowych, czy nie.</Styled.ListElement>
                <Styled.ListElement>Zapewniamy możliwość korzystania z funkcji społecznościowych, takich jak udostępnianie treści w serwisach społecznościowych oraz subskrypcja profilu społecznościowego. Korzystanie z tych funkcji wiąże się z wykorzystywaniem plików cookies administratorów serwisów społecznościowych takich jak Facebook czy LinkedIN.</Styled.ListElement>
            </Styled.List>
            <Styled.Paragraph>Jeżeli powyższe informacje nie są dla Ciebie wystarczające, poniżej znajdziesz dalej idące szczegóły.</Styled.Paragraph>

            <Styled.Subheader>Dane osobowe</Styled.Subheader>
            <Styled.Paragraph>Administratorem Twoich danych osobowych w rozumieniu przepisów o ochronie danych osobowych jest firma Pattern Applied Piotr Wyczesany, ul. Św. Filipa 23/3, 31-150 Kraków, NIP: 6792724626, REGON: 121005334.</Styled.Paragraph>
            <Styled.Paragraph>Cele, podstawy prawne oraz okres przetwarzania danych osobowych wskazane są odrębnie w stosunku do każdego celu przetwarzania danych (patrz: opis poszczególnych celów przetwarzania danych osobowych poniżej).</Styled.Paragraph>

            <Styled.Subheader>Uprawnienia</Styled.Subheader>
            <Styled.Paragraph>RODO przyznaje Ci następujące potencjalne uprawnienia związane z przetwarzaniem Twoich danych osobowych:</Styled.Paragraph>
            <Styled.List>
                <Styled.ListElement>prawo dostępu do danych osobowych,</Styled.ListElement>
                <Styled.ListElement>prawo do sprostowania danych osobowych,</Styled.ListElement>
                <Styled.ListElement>prawo do usunięcia danych osobowych,</Styled.ListElement>
                <Styled.ListElement>prawo do ograniczenia przetwarzania danych osobowych,</Styled.ListElement>
                <Styled.ListElement>prawo do wniesienia sprzeciwu co do przetwarzania danych osobowych,</Styled.ListElement>
                <Styled.ListElement>prawo do przenoszenia danych,</Styled.ListElement>
                <Styled.ListElement>prawo do wniesienia skargi do organu nadzorczego,</Styled.ListElement>
                <Styled.ListElement>prawo do odwołania zgody na przetwarzanie danych osobowych, jeżeli takową zgodę wyraziłeś.</Styled.ListElement>
            </Styled.List>
            <Styled.Paragraph>Zasady związane z realizacją wskazanych uprawnień zostały opisane szczegółowo w art. 16 – 21 RODO. Zachęcamy do zapoznania się z tymi przepisami. Ze swojej strony uważamy za potrzebne wyjaśnić Ci, że wskazane powyżej uprawnienia nie są bezwzględne i nie będą przysługiwać Ci w stosunku do wszystkich czynności przetwarzania Twoich danych osobowych. Dla Twojej wygody dołożyliśmy starań, by w ramach opisu poszczególnych operacji przetwarzania danych osobowych wskazać na przysługujące Ci w ramach tych operacji uprawnienia.</Styled.Paragraph>
            <Styled.Paragraph>Podkreślamy, że jedno z uprawnień wskazanych powyżej przysługuje Ci zawsze – jeżeli uznasz, że przy przetwarzaniu Twoich danych osobowych dopuściliśmy się naruszenia przepisów o ochronie danych osobowych, masz możliwość wniesienia skargi do organu nadzorczego (Prezesa Urzędu Ochrony Danych Osobowych).</Styled.Paragraph>
            <Styled.Paragraph>Zawsze możesz również zwrócić się do nas z żądaniem udostępnienia Ci informacji o tym, jakie dane na Twój temat posiadamy oraz w jakich celach je przetwarzamy. Wystarczy, że wyślesz wiadomość na adres piotr@patternapplied.com. Dołożyliśmy jednak wszelkich starań, by interesujące Cię informacje zostały wyczerpująco przedstawione w niniejszej polityce prywatności. Podany powyżej adres e-mail możesz wykorzystać również w razie jakichkolwiek pytań związanych z przetwarzaniem Twoich danych osobowych.</Styled.Paragraph>

            <Styled.Subheader>Bezpieczeństwo</Styled.Subheader>
            <Styled.Paragraph>Gwarantujemy Ci poufność wszelkich przekazanych nam danych osobowych. Zapewniamy podjęcie wszelkich środków bezpieczeństwa i ochrony danych osobowych wymaganych przez przepisy o ochronie danych osobowych. Dane osobowe są gromadzone z należytą starannością i odpowiednio chronione przed dostępem do nich przez osoby do tego nieupoważnione.</Styled.Paragraph>

            <Styled.Subheader>Wykaz powierzeń</Styled.Subheader>
            <Styled.Paragraph>Powierzamy przetwarzanie danych osobowych następującym podmiotom:</Styled.Paragraph>
            <Styled.List>
                <Styled.ListElement>nazwa.pl spółka z ograniczoną odpowiedzialnością z siedzibą w Krakowie (KRS: 0000594747), ul. Pana Tadeusza 2, 30-727 Kraków - w celu przechowywania danych osobowych na serwerze,</Styled.ListElement>
                <Styled.ListElement>MailerLite Limited, an Irish registered company at Ground Floor, 71 Lower Baggot Street, Dublin 2, D02 P593, Ireland -  w celu korzystania z systemu mailingowego, w którym przetwarzane są Twoje dane, jeżeli zapisałeś się do newslettera,</Styled.ListElement>
                <Styled.ListElement>Fakturownia Sp. z o.o. ul. Smulikowskiego 6/8, 00-389 Warszawa, NIP 5213704420 – w celu korzystania z systemu do fakturowania, w którym przetwarzane są Twoje dane, jeżeli wystawiamy dla Ciebie fakturę,</Styled.ListElement>
                <Styled.ListElement>Biuro Rachunkowe Perfekto, ul. Przemiarki 23/24, 30-384 Kraków, NIP: 7941604656, Regon: 123113861 - w celu korzystania z usług księgowych, które wiążą się z przetwarzaniem Twoich danych wtedy, gdy wystawiamy dla Ciebie fakturę,</Styled.ListElement>
                <Styled.ListElement>Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irlandia – podmiot zapewniający narzędzie do analizy statystyk (Google Analytics), narzędzie do reklam (Google Ads) oraz narzędzie do integracji tagów (Google Tag Manager).</Styled.ListElement>
            </Styled.List>
            <Styled.Paragraph>Wszystkie podmioty, którym powierzamy przetwarzanie danych osobowych, gwarantują stosowanie odpowiednich środków ochrony i bezpieczeństwa danych osobowych wymaganych przez przepisy prawa.</Styled.Paragraph>

            <Styled.Subheader>Newsletter</Styled.Subheader>
            <Styled.Paragraph>Jeżeli chcesz zapisać się do newslettera, musisz przekazać nam swój adres e-mail za pośrednictwem formularza zapisu do newslettera.</Styled.Paragraph>
            <Styled.Paragraph>Dane przekazane nam podczas zapisu do newslettera wykorzystywane są w celu przesyłania Ci newslettera, a podstawą prawną ich przetwarzania jest Twoja zgoda (art. 6 ust. 1 lit. a RODO) wyrażona podczas zapisywania się do newslettera.</Styled.Paragraph>
            <Styled.Paragraph>Dane przetwarzane są w ramach systemu mailingowego MailerLite i przechowywane na serwerze zapewnianym przez MailerLite Limited.</Styled.Paragraph>
            <Styled.Paragraph>Dane będą przetwarzane przez czas funkcjonowania newslettera, chyba że wcześniej zrezygnujesz z jego otrzymywania, co spowoduje usunięcie Twoich danych z bazy.</Styled.Paragraph>
            <Styled.Paragraph>W każdej chwili możesz sprostować swoje dane zapisane w bazie newsletterowej, jak również zażądać ich usunięcia, rezygnując z otrzymywania newslettera. Przysługuje Ci również prawo do przenoszenia danych, o którym mowa w art. 20 RODO.</Styled.Paragraph>

            <Styled.Subheader>Kontakt e-mailowy</Styled.Subheader>
            <Styled.Paragraph>Kontaktując się z nami za pośrednictwem poczty elektronicznej, w tym również przesyłając zapytanie poprzez formularz kontaktowy, w sposób naturalny przekazujesz nam swój adres e-mail jako adres nadawcy wiadomości. Ponadto, w treści wiadomości możesz zawrzeć również inne dane osobowe.            </Styled.Paragraph>
            <Styled.Paragraph>Twoje dane są w tym przypadku przetwarzane w celu kontaktu z Tobą, a podstawą przetwarzania jest art. 6 ust. 1 lit. f RODO, czyli prawnie uzasadniony interes administratora polegający na udzieleniu odpowiedzi na Twoją wiadomość. Podstawą prawną przetwarzania po zakończeniu kontaktu jest prawnie uzasadniony interes administratora polegający na archiwizacji korespondencji na potrzeby wewnętrzne (art. 6 ust. 1 lit. f RODO).</Styled.Paragraph>
            <Styled.Paragraph>Treść korespondencji może podlegać archiwizacji. Masz prawo do domagania się przedstawienia historii korespondencji, jaką z nami prowadziłeś (jeżeli podlegała archiwizacji), jak również domagać się jej usunięcia, chyba że jej archiwizacja jest uzasadniona z uwagi na nasze nadrzędne interesy, np. obrona przed potencjalnymi roszczeniami.</Styled.Paragraph>

            <Styled.Subheader>Pliki cookies i inne technologie śledzące</Styled.Subheader>
            <Styled.Paragraph>Nasza strona, podobnie jak niemal wszystkie inne strony internetowe, wykorzystuje pliki cookies, by zapewnić Ci najlepsze doświadczenia związane z korzystaniem z niej.</Styled.Paragraph>
            <Styled.Paragraph>Cookies to niewielkie informacje tekstowe, przechowywane na Twoim urządzeniu końcowym (np. komputerze, tablecie, smartfonie), które mogą być odczytywane przez nasz system teleinformatyczny.</Styled.Paragraph>
            <Styled.Paragraph>Więcej szczegółów znajdziesz poniżej.</Styled.Paragraph>

            <Styled.Subheader>Zgoda na cookies</Styled.Subheader>
            <Styled.Paragraph>Podczas pierwszej wizyty na stronie <Styled.Link href="https://patternapplied.com">https://patternapplied.com</Styled.Link> oraz <Styled.Link href="https://cqrsapplied.pl">https://cqrsapplied.pl</Styled.Link> wyświetlana jest Ci informacja na temat stosowania plików cookies wraz z pytaniem o zgodę na wykorzystywanie plików cookies. Dzięki specjalnemu narzędziu masz możliwość zarządzania plikami cookies z poziomu strony. Ponadto, zawsze możesz zmienić ustawienia cookies z poziomu swojej przeglądarki albo w ogóle usunąć pliki cookies. Pamiętaj jednak, że wyłączenie plików cookies może powodować trudności w korzystaniu ze strony, jak również z wielu innych stron internetowych, które stosują cookies.</Styled.Paragraph>

            <Styled.Subheader>Cookies podmiotów trzecich</Styled.Subheader>
            <Styled.Paragraph>Nasza strona, podobnie jak większość współczesnych stron internetowych, wykorzystuje funkcje zapewniane przez podmioty trzecie, co wiąże się z wykorzystywaniem plików cookies pochodzących od podmiotów trzecich. Wykorzystanie tego rodzaju plików cookies zostało opisane poniżej.</Styled.Paragraph>

            <Styled.Subheader>Analiza i statystyka</Styled.Subheader>
            <Styled.Paragraph>Wykorzystujemy cookies do śledzenia statystyk strony, takich jak liczba osób odwiedzających, rodzaj systemu operacyjnego i przeglądarki internetowej wykorzystywanej do przeglądania strony, czas spędzony na stronie, odwiedzone podstrony etc. Korzystamy w tym zakresie z Google Analytics, co wiąże się z wykorzystaniem plików cookies firmy Google LLC. W ramach mechanizmu do zarządzania ustawieniami plików cookies masz możliwość zadecydowania, czy wyrażasz zgodę na korzystanie przez nas w Twoim przypadku z cookies analitycznych, czy nie.</Styled.Paragraph>

            <Styled.Subheader>Marketing</Styled.Subheader>
            <Styled.Paragraph>Korzystamy z narzędzi marketingowych, takich jak Facebook Pixel, by kierować do Ciebie reklamy. Wiąże się to z wykorzystywaniem plików cookies firmy Facebook. W ramach ustawień plików cookies możesz zadecydować, czy wyrażasz zgodę na korzystanie przez nas w Twoim przypadku z cookies marketingowych, czy nie.</Styled.Paragraph>

            <Styled.Subheader>Narzędzia społecznościowe</Styled.Subheader>
            <Styled.Paragraph>Zapewniamy możliwość korzystania z funkcji społecznościowych, takich jak udostępnianie treści w serwisach społecznościowych oraz subskrypcja profilu społecznościowego. Korzystanie z tych funkcji wiąże się z wykorzystywaniem plików cookies administratorów serwisów społecznościowych takich jak Facebook oraz LinkedIN.</Styled.Paragraph>
            <Styled.Paragraph>Osadzamy na stronie nagrania wideo z serwisu YouTube. Gdy odtwarzasz takie nagrania, wykorzystywane są pliki cookies firmy Google LLC dotyczące usługi YouTube.</Styled.Paragraph>

            <Styled.Subheader>Logi serwera</Styled.Subheader>
            <Styled.Paragraph>Korzystanie ze strony wiąże się z przesyłaniem zapytań do serwera, na którym przechowywana jest strona. Każde zapytanie skierowane do serwera zapisywane jest w logach serwera.</Styled.Paragraph>
            <Styled.Paragraph>Logi obejmują m.in. Twój adres IP, datę i czas serwera, informacje o przeglądarce internetowej i systemie operacyjnym, z jakiego korzystasz. Logi zapisywane i przechowywane są na serwerze.</Styled.Paragraph>
            <Styled.Paragraph>Dane zapisane w logach serwera nie są kojarzone z konkretnymi osobami korzystającymi ze strony i nie są wykorzystywane przez nas w celu Twojej identyfikacji.</Styled.Paragraph>
            <Styled.Paragraph>Logi serwera stanowią wyłącznie materiał pomocniczy służący do administrowania stroną, a ich zawartość nie jest ujawniana nikomu poza osobami upoważnionymi do administrowania serwerem.</Styled.Paragraph>

            <Styled.Subheader>Zmiany polityki prywatności</Styled.Subheader>
            <Styled.Paragraph>Polityka jest na bieżąco weryfikowana i w razie potrzeby aktualizowana.</Styled.Paragraph>
            <Styled.Paragraph>Aktualna wersja polityki została przyjęta i obowiązuje od 01.09.2023 roku.</Styled.Paragraph>
        </ContentWrapper>
    </Styled.Wrapper>
)