import React from 'react';
import {Helmet} from 'react-helmet';
import {string} from 'prop-types';
import Layout from '../components/Layout';
import { PrivacyPolicy } from '../components/PrivacyPolicy/PrivacyPolicy';

const PrivacyPolicyPage = ({path}) => {
    return (
        <Layout path={path}>
            <Helmet>
                <title>Polityka Prywatności - PatternApplied.com</title>
                <meta name='description' content='Dzień dobry! Jeżeli tutaj trafiłeś, to niezawodny znak, że cenisz swoją prywatność. Doskonale to rozumiemy, dlatego przygotowaliśmy dla Ciebie ten dokument, w którym znajdziesz zasady przetwarzania danych osobowych oraz wykorzystywania plików cookies w związku z korzystaniem ze strony internetowej https://patternapplied.com oraz https://cqrsapplied.pl.'/>
            </Helmet>
            <PrivacyPolicy />
        </Layout>
    )
}

PrivacyPolicyPage.propTypes = {
    path: string.isRequired,
}

export default PrivacyPolicyPage;
